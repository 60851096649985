import { request } from '@/api/common'
import apis from '@/common/config/host'
// import { requireParams } from '@/common/const/api-require-param'
const GET_FEEDBACK_INFO = apis.cApiDomain + '/complaint/missItemInfo'
const POST_FEEDBACK_DATA = apis.cApiDomain + '/complaint/missItem'
const UPLOAD_BASE64_IMAGE = apis.cApiDomain + '/common/uploadbase64'
const GET_APPEAL_PROGRESS = apis.cApiDomain + '/complaint/process'
const GET_REFUND_REE = apis.cApiDomain + '/complaint/getRefundFee'
const POST_APPEAL_DATA = apis.cApiDomain + '/complaint/missItemsAppeal'
const GET_FEEDBACK_CONFIGS = apis.cApiDomain + '/complaint/getMissItemConfigs'
const SET_DEF_REFUND_CHANNEL = apis.cApiDomain + '/complaint/setDefRefundChannel'
const GET_PRE_AFTER_SALE_INFO = apis.fulfillmentApiDomain + '/complaint/getPreAftersaleInfo'

export const getFeedbackInfo = function (data) {
  return request.post(GET_FEEDBACK_INFO, data)
}

export const postFeedbackData = function (data) {
  return request.post(POST_FEEDBACK_DATA, data)
}

export const uploadBase64Image = function (data) {
  return request.post(UPLOAD_BASE64_IMAGE, data)
}

// 获取售后申诉进度
export const getAppealProgress = function (data) {
  // 桌面浏览器请求使用的默认参数
  // data.data = {...requireParams, ...data.data}
  return request.post(GET_APPEAL_PROGRESS, data)
}

// 实时获取退款金额
export const getRefundFee = function (data) {
  // 桌面浏览器请求使用的默认参数
  // data.data = {...requireParams, ...data.data}
  return request.post(GET_REFUND_REE, data)
}

export const appealToService = function (data) {
  // 桌面浏览器请求使用的默认参数
  // data.data = {...requireParams, ...data.data}
  return request.post(POST_APPEAL_DATA, data)
}

export const getMissItemConfigs = function (data) {
  // 桌面浏览器请求使用的默认参数
  return request.post(GET_FEEDBACK_CONFIGS, data)
}

// 设置默认退款渠道
export const setDefrefundChannel = function (data) {
  // 桌面浏览器请求使用的默认参数
  return request.post(SET_DEF_REFUND_CHANNEL, data)
}

// 售后前弹窗说明
export const getPreAfterSaleInfo = function (data) {
  return request.post(GET_PRE_AFTER_SALE_INFO, data)
}

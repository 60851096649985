import i18n from '@/common/js/i18n'
import { sprintf } from '@didi/gettext'

const payMessageObj = {
  title: i18n._('支付信息'),
  desc: sprintf(i18n._('%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。'), 'DiDi'),
  // 支付FAQ
  QAList: [
    {
      title: i18n._('app内可以支持哪些支付方式？'),
      answer: [i18n._('目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。')]
    },
    {
      title: i18n._('怎么样开通免密支付？'),
      answer: [i18n._('在支付方式中添加您的银行卡，成功绑定后即可开通免密支付。')]
    },
    {
      title: i18n._('如果对费用提出争议，将如何解决？'),
      answer: [i18n._('在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。')]
    }
  ]
}
export const walletPaymentType = {
  'MX': payMessageObj,
  'BR': payMessageObj,
  'CR': payMessageObj,
  'CO': payMessageObj,
  'DO': payMessageObj,
  'CL': payMessageObj,
  'PE': payMessageObj,
  'JP': {
    title: i18n._('支付信息'),
    desc: sprintf(i18n._('%s致力于通过无密码和现金支付流程为我们的客户提供简单安全的付款体验。'), '99'),
    // 支付FAQ
    QAList: [
      {
        title: i18n._('app内可以支持哪些支付方式？'),
        answer: [i18n._('目前我们支持信用卡，借记卡的线上支付，以及现金和pos机的线下支付。')]
      },
      {
        title: i18n._('如果对费用提出争议，将如何解决？'),
        answer: [i18n._('在极少数情况下，如果您对订单费用产生疑议，可以点击“帮助”联系我们，客服团队会尽快为您解答。')]
      }
    ]

  }
}

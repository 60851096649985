import { request } from '@/api/common'
import apis from '@/common/config/host'

const GET_TAG_LIST = apis.cApiDomain + '/evaluation/tagList'
const SUBMIT_EVALUATION = apis.cApiDomain + '/evaluation/evaluate'
const GET_EVALUATION_INFO = apis.cApiDomain + '/evaluation/evaluationInfo'

export const getTags = function (data) {
  return request.post(GET_TAG_LIST, data)
}

export const submitEvaluation = function (data) {
  return request.post(SUBMIT_EVALUATION, data)
}

export const getEvaluationInfo = function (data) {
  return request.post(GET_EVALUATION_INFO, data)
}

<!--
@Author: wangdaohan
@Date:   2018-03-23T19:13:19+08:00
@Email:  wangdaohan@didichuxing.com
@Filename: index.vue
@Last modified by:   wangdaohan
@Last modified time: 2018-04-01T15:40:44+08:00
-->
<template>
  <div class="waiting">
    404
    <!-- <div class="bg"></div>
    <img src="./tl.png" alt="" class="tl">
    <img src="./rb.png" alt="" class="rb"> -->
  </div>
</template>

<script>
import { omegaH5Monitor } from '@/common/js/tools.js';

const Waiting = {
  props: [],
  data () {
    return {
    }
  },
  mounted () {
    try {
       this.init()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async init() {
      const userInfo = await this.getUserInfo()
      // 404页面埋点
      omegaH5Monitor({ 
        type: '404', 
        msg: 'vue_page_404',
        uid: userInfo.uid || -1
      }) 
    },
    async getUserInfo () {
      const res = await SodaBridge('getUserInfo') || {}
      return res.data || {}
    },
  }
}
export default Waiting
</script>

<style scoped lang="stylus">
.waiting{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  .bg{
    width: 100%;
    height: 100%;
    background: url(./waiting.jpg);
    background-position: center;
    background-size: cover;
  }
}
.tl{
  position: absolute;
  top: 0;
  left: 0;
  width: 47%;
}
.rb{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 22%;
}
</style>

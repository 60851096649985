import { request } from '@/api/common'
import apis from '@/common/config/host'

const FEED_NOSHOP = apis.cApiDomain + '/feed/noShop'
const FEED_UNOPENED = apis.cApiDomain + '/feed/unopened'
const ENTREGA_CITYLIST = apis.cApiDomain + '/entrega/cityList'
const ADDRESS_REVERSEPOI = apis.cApiDomain + '/address/reversePoi'
const GET_RIDER_AREA = apis.cApiDomain + '/zone/getRiderArea'
const GET_CLUSTER_LIST = apis.cApiDomain + '/zone/getClusterList'

export const feedNoShop = function (data = {}) {
  return request.post(FEED_NOSHOP, data)
}

export const feedUnOpened = function (data = {}) {
  return request.post(FEED_UNOPENED, data)
}

export const entregaCityList = function (data = {}) {
  return request.post(ENTREGA_CITYLIST, data)
}

export const addressReversePoi = function (data = {}) {
  return request.post(ADDRESS_REVERSEPOI, data)
}

export const getRiderArea = function (data = {}) {
  return request.post(GET_RIDER_AREA, data)
  // return request.get(GET_RIDER_AREA, data)
}

export const getClusterList = function (data = {}) {
  return request.post(GET_CLUSTER_LIST, data)
}

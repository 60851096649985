import emitter from './emitter'
export const Soda = window.Soda || {
  customer: {}
}

export class BridgeMonitor {
  constructor(module, method) {
    this.fnList = []
    Soda[module][method] = (...args) => {
      emitter.emit(`${module}.${method}`, ...args)
    }

    emitter.on(`${module}.${method}`, (...res) => {
      this.fnList.forEach((fn) => {
        fn(...res)
      })
    })
  }

  register(fn) {
    this.fnList.push(fn)
  }

  unregister(fn) {
    let index = this.fnList.indexOf(fn)
    if (index !== -1) {
      this.fnList.splice(index, 1)
    }
  }
}

function getNotFoundRes() {
  return { errno: 404, errmsg: 'NOT FOUND', data: {} }
}

function bridgeReport({ name, time, errno, errMsg }) {
  emitter.emit('bridge-report', {
    name,
    time,
    errno,
    errMsg
  })
}

function checkSoda() {
  return new Promise((resolve, reject) => {
    if (window.Soda !== undefined) {
      resolve()
    } else {
      let t = setInterval(() => {
        if (window.Soda !== undefined) {
          resolve()
          clearInterval(t)
        }
      }, 100)
    }
  })
}

export function bridgePromisify(
  bridgeFn,
  bridgeName,
  isGlobalParamsBridge = false
) {
  return (params = {}) => {
    return new Promise((resolve) => {
      const startRequestTime = Date.now()

      try {
        checkSoda()
          .then(() => {
            if (isGlobalParamsBridge) {
              // getGlobalParams
              bridgeFn((res) => {
                resolve(res)
                bridgeReport({
                  name: bridgeName,
                  time: Date.now() - startRequestTime,
                  errno: res.errno,
                  errMsg: res.errmsg
                })
              })
            } else {
              bridgeFn(params, (res) => {
                resolve(res)
                bridgeReport({
                  name: bridgeName,
                  time: Date.now() - startRequestTime,
                  errno: res.errno,
                  errMsg: res.errmsg
                })
              })
            }
          })
          .catch((e) => {
            bridgeReport({
              name: bridgeName,
              time: Date.now() - startRequestTime,
              errno: 404,
              errMsg: e
            })
            resolve(getNotFoundRes())
          })
      } catch (e) {
        bridgeReport({
          name: bridgeName,
          time: Date.now() - startRequestTime,
          errno: 404,
          errMsg: e
        })
        resolve(getNotFoundRes())
      }
    })
  }
}

/**
 * 获取 updateNav 参数信息
 */
export function getUpdateNavParams(type = 'light') {
  let config = {
    transparent: {
      sysStatusBar: 1, // 状态栏字体颜色 0黑色,1白色
      nav: {
        // titlebar背景条
        color: '#FFFFFF',
        alpha: 0,
        hidden: 1 // 隐藏头部
      }
    },
    light: {
      sysStatusBar: 0,
      nav: {
        color: '#FFFFFF',
        alpha: 0,
        hidden: 1
      }
    },
    dark: {
      sysStatusBar: 1,
      nav: {
        color: '#000000',
        alpha: 1,
        hidden: 1
      }
    }
  }
  // TODO 由于现在还不支持不传这几个字段 所以先加上
  let other = {
    backBtn: {
      alpha: 1,
      color: '#FFFFFF'
    },
    title: {
      color: '#000000',
      alpha: 1
    }
  }
  return Object.assign(config[type], other)
}

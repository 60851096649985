const CancelOrder = {
  path: '/entrega/cancel-order',
  name: 'cancelOrder',
  meta: {
    title: 'cancelOrder'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/entrega/cancel-order/index')))
  }
}
const CancelOrderQuestion = {
  path: '/entrega/question/rules',
  name: 'CancelOrderQuestion',
  meta: {
    title: 'CancelOrderQuestion'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/entrega/question/rules')))
  }
}

export default [CancelOrder, CancelOrderQuestion]

import { request } from '@/api/common'
import apis from '@/common/config/host'

const GET_VALID_COUPONS_BY_CATE = apis.actApiDomain + '/act-api/api/sidebar/validCouponsByCate'

const GET_MEMBERSHIP_STORE_COUPON_LIST  = apis.actApiDomain + '/act-api/api/benefitgrowth/getMembershipStoreCouponList'

export const getValidCouponsByCate = function (data) {
  return request.post(GET_VALID_COUPONS_BY_CATE, {data: {...data}})
}

// 会员商家券可用页面
export const getMembershipStoreCouponList = function (data) {
  return request.post(GET_MEMBERSHIP_STORE_COUPON_LIST, {data: {...data}})
}

import { request } from '@/api/common'
import apis from '@/common/config/host'

const GET_COUPON_LIST = apis.cApiDomain + '/coupon/fetchCouponList'
// 闪送提单页优惠券接口
const ENTREGA_COUPON_LIST = apis.cApiDomain + '/entrega/couponList'

const EXCHANGE_COUPON = apis.actApiDomain + '/act-api/api/code/cartRedeem'

export const getCoupons = function (data) {
  return request.post(GET_COUPON_LIST, data)
}
export const entregaCouponList = function (data) {
  return request.post(ENTREGA_COUPON_LIST, data)
}
export const exchangeCoupon = function (data) {
  return request.post(EXCHANGE_COUPON, data)
}

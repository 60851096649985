// 巴西满减券
const RCoupon = {
  path: '/sidebar/r/coupon',
  name: 'sidebarRCoupon',
  meta: {
    title: 'r-coupon'
  },
  component: () =>
    import(
      /* webpackChunkName: "sidebar-r-coupon" */
      '../page/sidebar/r/coupon/index'
    )
}

const Coupon = {
  path: '/sidebar/coupon',
  name: 'sidebarCoupon',
  meta: {
    title: 'coupon'
  },
  component: () =>
    import(
      /* webpackChunkName: "sidebar-coupon" */
      '../page/sidebar/coupon/index'
    )
}
const CouponSuccess = {
  path: '/sidebar/coupon-success',
  name: 'sidebarCouponSuccess',
  meta: {
    title: 'coupon-success'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/sidebar/coupon/success')))
  }
}
const CouponUnavailable = {
  path: '/sidebar/coupon-unavailable',
  name: 'couponUnavailable',
  meta: {
    title: 'coupon-success'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/sidebar/coupon/unavailable')))
  }
}
const Membership = {
  path: '/sidebar/membership',
  name: 'sidebarMembership',
  meta: {
    title: 'membership'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/sidebar/membership/index')))
  }
}
export default [Coupon, CouponSuccess, CouponUnavailable, Membership, RCoupon]

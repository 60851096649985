import track from './track'
// 取消确认页面展示
export function apiErrnoError (options) {
  try {
    track('tech_c_api_errno_erroe', '接口返回异常', {
      ...options
    }, null)
  } catch (err) {}
}

/**
 *  @desc 路由错误捕捉
 * */
export const routerErrorLog = (options = {}) => {
  try {
    track('sailing_c_h5_tech_js_error_bt','路由加载错误捕捉', {
      err_type: 'routerOnError',
      ...options,
    }, null)
  } catch (err) {
    console.log(err);
  }
};

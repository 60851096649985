import Vue from "vue";
import Router from "vue-router";

import Sidebar from "./sidebar"; // 侧边栏页面
import Cart from "./cart"; // 购物车页面
import USerGuide from "./user-guide"; // 用户指南类静态页
import Order from "./order";
import Deeplink from "./deeplink";
import Delivery from "./delivery";
import Abnormal from "./abnormal";
import Entrega from "./entrega";
import Merchants from "./merchants";
import vfm from "./vfm";
import soloDining from "./soloDining";
import topic from "./topic";
import user from "./user";
import { getLocale } from "@didi/gettext";
import { routerErrorLog } from "@/common/js/trackApiError";
import { routerRedirectCatch } from "@/common/js/router";
import bill from "./bill";
import page404 from '@/page/activity/waiting/index.vue'

Vue.use(Router);
routerRedirectCatch(Router);

const notFount = {
  path: "*",
  name: "notFound",
  component: page404
};

const locale = getLocale();
const router = new Router({
  mode: 'history',
  base: locale || '/',
  routes: [
    notFount,
    ...Sidebar,
    ...Cart,
    ...USerGuide,
    ...Order,
    ...Deeplink,
    ...Delivery,
    ...Abnormal,
    ...Entrega,
    ...Merchants,
    ...vfm,
    ...soloDining,
    ...topic,
    ...user,
    ...bill,
  ],
});
router.onError((error) => {
  try {
    // 拦截路由懒加载失败报错
    const pattern = /Loading (?:CSS\s)?chunk (\d)+ failed/g;
    const loadFailed = error?.message.match(pattern);
    if (loadFailed) {
      const targetPath = router.history?.pending?.fullPath;
      routerErrorLog({
        err_info: error?.message,
        path: targetPath,
      });
    }
  } catch {}
});

export default router;

import sim from '../sim.js'

let production = {
  cApiDomain: '',
  actApiDomain: '',
  entregaApiDomain: '',
  fulfillmentApiDomain: '',
};

if (sim.isSim()) {
  production = {
    cApiDomain: sim.getSimCApi(),
    actApiDomain: sim.getSimActApi(),
    entregaApiDomain: sim.getSimEntregaApi(),
    fulfillmentApiDomain: sim.getSimFulfillmentApi(),
  };
} else {
  // for didi brand
  const isPre = location.host.indexOf('pre-c-h5') !== -1
  production = {
    cApiDomain: isPre
      ? 'https://pre-c.didi-food.com'
      : 'https://c.didi-food.com',
    actApiDomain: isPre
      ? 'https://pre-act-api.didi-food.com'
      : 'https://act-api.didi-food.com',
    entregaApiDomain: '//entrega.didi-food.com',
    fulfillmentApiDomain: isPre
      ? 'https://pre-fulfillment.didi-food.com'
      : 'https://fulfillment.didi-food.com',
  };
}

export default production

const defaultPic =
  'https://pt-starimg.didistatic.com/static/starimg/img/7f6FPB2Eb91646278291043.png';

// 闪送服务规则计价图
export const valuationImg = {
  BR: 'https://pt-starimg.didistatic.com/static/starimg/img/959tHgMsv31655803497692.png',
  MX: defaultPic,
  JP: defaultPic,
  CR: defaultPic,
  CO: 'https://img0.didiglobal.com/static/soda_static/xpub_base_landingpage/entrega_co_fee.jpg',
  DO: defaultPic,
  CL: defaultPic,
};

const Deeplink = {
  path: '/deeplink',
  name: 'deeplink',
  meta: {
    title: 'deeplink'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/deeplink/index')))
  }
}
const AppLinkDidi = {
  path: '/app-link/didifood',
  name: 'AppLinkDidi',
  meta: {
    title: 'AppLinkDidi'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/deeplink/index')))
  }
}
const AppLink99 = {
  path: '/app-link/99food',
  name: 'AppLink99',
  meta: {
    title: 'AppLink99'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/deeplink/index')))
  }
}
const DeeplinkFB = {
  path: '/deeplinkFB',
  name: 'deeplinkFB',
  meta: {
    title: 'deeplinkFB'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/deeplinkFB/index')))
  }
}
export default [Deeplink, DeeplinkFB, AppLinkDidi, AppLink99]

// 劫持 push replace方法拦截错误
export function routerRedirectCatch(Router) {
  // https://blog.csdn.net/mm0715/article/details/112768494
  const originalPush = Router.prototype.push;
  const originalReplace = Router.prototype.replace;

  Router.prototype.push = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => err);
  };

  Router.prototype.replace = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch((err) => err);
  };
}

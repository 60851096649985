import { request } from '@/api/common'
import i18n from '@/common/js/i18n'
import store from '@/store'
import apis from '@/common/config/host'
const cancelDutyUrl = apis.cApiDomain + '/order/cancelDutyV2'
const cancelUrl = apis.cApiDomain + '/order/cancelV2'
const getCancelReasonUrl = apis.cApiDomain + '/order/getCancelReason'
const cancelOrderUrl = apis.cApiDomain + '/order/cancelReason'
const getCancelFlowUrl = apis.cApiDomain + '/cancel/applyFlow'
const applyCancelUrl = apis.cApiDomain + '/cancel/apply'

// 取消订单预判责
export function cancelDuty (params = {}) {
  return cancelRequest({
    url: cancelDutyUrl,
    params
  })
}
// 取消订单
export function cancelOrder (params = {}) {
  return cancelRequest({
    url: cancelUrl,
    params
  })
}
// 获取关闭原因
export function getCancelReason (params = {}) {
  return cancelRequest({
    url: getCancelReasonUrl,
    params
  })
}
// 发送取消原因
export function cancelReason (params = {}) {
  return cancelRequest({
    url: cancelOrderUrl,
    params
  })
}
// 提交申请取消
export function applyCancel (params = {}) {
  return cancelRequest({
    url: applyCancelUrl,
    params
  })
}
// 获取取消进度
export function getCancelFlow (params = {}) {
  return cancelRequest({
    url: getCancelFlowUrl,
    params
  })
}
// 包装公共request
function cancelRequest (option = {}) {
  const { params } = option
  return request.post(option.url, {
    data: params.data
  })
    .then((res) => {
      const resData = res.data
      if (resData.errno === 0) {
        return Promise.resolve(resData.data)
      } else if (resData.errno === 40000) {
        // 状态码为40000的时候，后端的errmsg为系统内部错误，需要映射一下文案
        store.commit('SHOW_MSG', {
          type: 'error',
          txt: i18n._('网络不佳，请检查您的网络')
        })
        return Promise.reject(resData.errno)
      } else if (resData.errno === 41202) {
        // 信息发生变化，请重新确认 刷新页面
        setTimeout(() => {
          params.refreshCallback && params.refreshCallback()
        }, 0)
        return Promise.reject(resData.errno)
      } else if (resData.errno === 41203) {
        // 订单状态发生变化，请重新确认  处理：返回订单详情页
        setTimeout(() => {
          params.backCallback && params.backCallback()
        }, 1500)
        return Promise.reject(resData.errno)
      } else {
        store.commit('SHOW_MSG', {
          type: 'error',
          txt: i18n._('网络不佳，请检查您的网络')
        })
        return Promise.reject(resData.errno)
      }
    })
    .catch((err) => {
      console.error(`API cancelRequest error`, err)
      if (typeof err === 'number' || typeof err === 'string') {
        return Promise.reject(err)
      }
    })
}

const CancelOrder = {
  path: '/order/cancel-order',
  name: 'cancelOrder',
  meta: {
    title: 'cancelOrder'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/cancel-order/index')))
  }
}
const CancelOrderReason = {
  path: '/order/cancel-order/reason',
  name: 'CancelOrderReason',
  meta: {
    title: 'CancelOrderReason'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/cancel-order/reason')))
  }
}
const CancelOrderResult = {
  path: '/order/cancel-order/result',
  name: 'CancelOrderResult',
  meta: {
    title: 'CancelOrderResult'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/cancel-order/result')))
  }
}
const Evaluation = {
  path: '/order/evaluation',
  name: 'evaluation',
  meta: {
    title: 'evaluation'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/evaluation/index')))
  }
}
const Questionlist = {
  path: '/order/question/list',
  name: 'Questionlist',
  meta: {
    title: 'questionlist'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/question/list')))
  }
}
const QuestionDetail = {
  path: '/order/question/detail',
  name: 'QuestionDetail',
  meta: {
    title: 'questionDetail'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/question/detail')))
  }
}
const Reparation = {
  path: '/order/reparation',
  name: 'reparation',
  meta: {
    title: 'reparation'
  },
  component: () =>
    import(
      /* webpackChunkName: "order-reparation" */
      '../page/order/reparation/index'
    )
}
const Feedback = {
  path: '/order/feedback',
  name: 'feedback',
  meta: {
    title: 'feedback'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/feedback/index')))
  }
}

const AppealToService = {
  path: '/order/appealToService',
  name: 'appealToService',
  meta: {
    title: 'appealToService'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/feedback/appealToService')))
  }
}

// 整单未收到
const MissOrder = {
  path: '/order/miss-order',
  name: 'missOrder',
  meta: {
    title: 'MissOrder'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/miss-order/index')))
  }
}

const PurchasingAgent = {
  path: '/order/purchasingAgent',
  name: 'purchasingAgent',
  meta: {
    title: 'purchasingAgent'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/explain/purchasing-agent')))
  }
}

const RuleEducation = {
  path: '/order/ruleEducation',
  name: 'ruleEducation',
  meta: {
    title: 'ruleEducation'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/education/rule-education')))
  }
}

// 风控拦截页面
const RiskManage = {
  path: '/order/risk-manage',
  name: 'riskManage',
  meta: {
    title: 'riskManage'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/order/risk-manage/index')))
  }
}

export default [CancelOrder, CancelOrderReason, CancelOrderResult, Evaluation, Questionlist, QuestionDetail, Reparation, Feedback, AppealToService, MissOrder, PurchasingAgent, RuleEducation, RiskManage]

import i18nNew from '@/i18n/locale'

// 默认配置
const defaultConf = {
  title: i18nNew.t('FoodC_name_DiDi_Flash_TAPX')
}
// 巴西99配置
const brConf = {
  title: i18nNew.t('Entrega_name_99_Flash_maAd')
}

export const config = {
  BR: brConf,
  MX: defaultConf,
  JP: defaultConf,
  CR: defaultConf,
  CO: defaultConf,
  DO: defaultConf,
  CL: defaultConf
}

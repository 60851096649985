const vfm = {
  path: "/vfm",
  name: "vfm",
  meta: {
    title: "vfm",
  },
  component(resolve) {
    require.ensure([], () => resolve(require("../page/vfm/index")));
  },
};
export default [vfm];

const CouponInfo = {
  path: '/cart/couponInfo',
  name: 'cartCouponInfo',
  meta: {
    title: 'cartCouponInfo'
  },
  component: () =>
    import(
      /* webpackChunkName: "cart-coupon" */
      '../page/cart/couponInfo/index'
    )
}
const Coupon = {
  path: '/cart/coupon',
  name: 'cartCoupon',
  meta: {
    title: 'coupon'
  },
  component: () =>
    import(
      /* webpackChunkName: "cart-coupon" */
      '../page/cart/coupon/index'
    )
}
const RCoupon = {
  path: '/cart/r/coupon',
  name: 'cartRCoupon',
  meta: {
    title: 'Rcoupon'
  },
  component: () =>
    import(
      /* webpackChunkName: "cart-r-coupon" */
      '../page/cart/r/coupon/index'
    )
}

const Tip = {
  path: '/cart/tip',
  name: 'cartTip',
  meta: {
    title: 'tip'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/cart/tip/index')))
  }
}

const Reduction = {
  path: '/cart/reduction',
  name: 'cartReduction',
  meta: {
    title: 'reduction'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/cart/reduction/index')))
  }
}

const CPFCheck = {
  path: '/cart/cpf-check',
  name: 'CPFCheck',
  meta: {
    title: 'CPFCheck'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/cart/CPF-check/index')))
  }

}

const Debugbridge = {
  path: '/debugbridge',
  name: 'debugbridge',
  meta: {
    title: 'debugbridge'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/debugbridge/index.vue')))
  }
}



export default [Coupon, RCoupon, Tip, Reduction, CPFCheck, Debugbridge, CouponInfo]

class Sim {
  isSim() {
    return location.host.startsWith('sim');
  }
  getLocationOrigin() {
    return location.origin;
  }
  getBaseUrl() {
    return this.getLocationOrigin().replace('.c-h5', '');
  }
  getSimCApi() {
    return `${this.getBaseUrl()}/c-api`;
  }
  getSimActApi() {
    return `${this.getBaseUrl()}/act-api`;
  }
  getSimEntregaApi() {
    return `${this.getBaseUrl()}/c-api`;
  }
  getSimFulfillmentApi() {
    return `${this.getBaseUrl()}/fulfillment-api`;
  }
}

export default new Sim()

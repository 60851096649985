<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import message from './mixin/message'

export default {
  name: 'App',
  mixins: [message]
}
</script>

<style>
@import '@didi/soda-c-coupon/lib/coupon.css';
@font-face {
  font-family: 'Aspira Demi';
  src: url('./common/fonts/DiDiSans-Pro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* 默认英文 & 西语 Regular字体 */
@font-face {
  font-family: 'Aspira Regular';
  src: url('./common/fonts/DiDiSans-Pro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* 英文 & 西语 Aspira-Medium */
@font-face {
  font-family: 'Aspira Medium';
  src: url('./common/fonts/DiDiSans-Pro-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* 英文 & 西语 Aspira-Light */
@font-face {
  font-family: 'Aspira Light';
  src: url('./common/fonts/DiDiSans-Pro-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Bold-Italic */
@font-face {
  font-family: 'DiDiSans-Pro-Bold-Italic';
  src: url('./common/fonts/DiDiSans-Pro-Bold-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Bold */
@font-face {
  font-family: 'DiDiSans-Pro-Bold';
  src: url('./common/fonts/DiDiSans-Pro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Italic */
@font-face {
  font-family: 'DiDiSans-Pro-Italic';
  src: url('./common/fonts/DiDiSans-Pro-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Medium-Italic */
@font-face {
  font-family: 'DiDiSans-Pro-Medium-Italic';
  src: url('./common/fonts/DiDiSans-Pro-Medium-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Medium */
@font-face {
  font-family: 'DiDiSans-Pro-Medium';
  src: url('./common/fonts/DiDiSans-Pro-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Regular */
@font-face {
  font-family: 'DiDiSans-Pro-Regular';
  src: url('./common/fonts/DiDiSans-Pro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Thin-Italic */
@font-face {
  font-family: 'DiDiSans-Pro-Thin-Italic';
  src: url('./common/fonts/DiDiSans-Pro-Thin-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* DiDiSans-Pro-Thin */
@font-face {
  font-family: 'DiDiSans-Pro-Thin';
  src: url('./common/fonts/DiDiSans-Pro-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'Aspira Regular', 'PingFang SC', 'Microsoft YaHei';
}

.iconfont {
  font-family: iconfont;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
}
.full-page {
  width: 100vw;
  height: 100vh;
}
#app {
  height: 100%;
  overflow: hidden;
  background: #fff;
}
</style>

import { request } from '@/api/common'
import apis from '@/common/config/host'

const GET_QUESTION_LIST = apis.cApiDomain + '/appeal/questionList'
const GET_QUESTION_DETAIL = apis.cApiDomain + '/appeal/questionDetail'
const SUBMIT_NOT_RECEIVE_FOOD = apis.cApiDomain + '/complaint/missFood'

export const getQuestionList = function (data) {
  return request.post(GET_QUESTION_LIST, data)
}

export const getQuestionDetail = function (data) {
  return request.post(GET_QUESTION_DETAIL, data)
}

export const submitNotReceiveFood = function (data) {
  return request.post(SUBMIT_NOT_RECEIVE_FOOD, data)
}

import didiaxios from '@didi/dajax/dist/dajax'

const BASE_URL = '//c.didi-food.com'

const COMMON_CONFIG = BASE_URL + '/common/config' // 根据经纬度反解country接口

export const getAddress = function (data) {
  return requestPOST(COMMON_CONFIG, data)
}

function requestPOST (url, data) {
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

/**
 * 磨平差异：端差异、平台差异，对业务层透明
 * 明确参与：让业务层更好使用
 * *注意循环引用问题 util等区分好使用范围和场景
 */
import {
  getGlobalParams,
  openPage,
  closePage,
  goBack,
  getUserInfo,
  getLocationInfo,
  setTitle as _setTitle,
  requestLogin,
  updateBill,
  updateNav,
  updateGlobalCart,
  registerTrigger,
  unregisterTrigger,
  triggerBillEvent,
  triggerAddressChanged,
  triggerWebViewLifecycleEvent,
  guideParams,
  addCartEvent,
  setBackPressListener,
  request,
  bridgeTrackEvent,
  pullUpPayment,
} from './core';
import { util } from '@didi/soda-common';

let {
  env: { isAndroid, inNative },
} = util;

function setTitle({ title }) {
  return _setTitle({ navi_title: title });
}

const TriggerList = [
  'triggerWebViewLifecycleEvent',
  'triggerBillEvent',
  'triggerAddressChanged',
];

function initBridge() {
  return registerTrigger({
    triggerList: TriggerList,
  }).then((res) => {
    // console.log('注册的trigger信息是否成功', res);
  });
}

// 判断登录信息 需要登录调登录
async function checkLoginBridge(login = true) {
  const userData = await getUserInfo({});
  if (userData?.data?.token) {
    return true;
  } else {
    //登录
    login &&
      (await requestLogin({ dontPopRoot: 1 }).then((res) => {
        if (res?.data?.token) {
          //todo
          location.reload();
        }
      }));
    return false;
  }
}

// 关闭开启手势返回
function closeNativeGestureBack(open, cb) {
  if (!inNative) return;
  // 安卓
  isAndroid &&
    setBackPressListener(open).then((res) => {
      cb && cb(res);
    });
  // isIOS
}

/**
 * 这里与axios拦截器的返回结果保持一致
 *
 * request桥返回bridge response
 * bridge response.data === http response
 * axios拦截器返回 http response
 * requestDataHelper的输入为 http response
 *
 * bridge request 返回值参考：https://img-hxy021.didistatic.com/static/starimg/img/98aNIla9u11669266386319.png
 * 请求各层输入、输出梳理：https://img-hxy021.didistatic.com/static/starimg/img/7g5G2CxyMs1669962130211.png
 */
function bridgePostRequest(url, data, config) {
  return request({
    method: 'post',
    url,
    data,
    header: config.header,
    timeout: config.timeout,
    ...config,
  }).then((response) => response?.data);
}

function exists(name) {
  let keys = name.split('.');
  let exist = true;
  let data = window;
  for (let i = 0; i < keys.length; i++) {
    if (data[keys[i]] !== undefined) {
      data = data[keys[i]];
    } else {
      exist = false;
      break;
    }
  }
  return exist;
}

export {
  exists,
  getGlobalParams,
  openPage,
  closePage,
  goBack,
  getUserInfo,
  getLocationInfo,
  setTitle,
  requestLogin,
  updateBill,
  updateNav,
  updateGlobalCart,
  initBridge,
  registerTrigger,
  unregisterTrigger,
  triggerBillEvent,
  triggerAddressChanged,
  triggerWebViewLifecycleEvent,
  guideParams,
  addCartEvent,
  checkLoginBridge,
  closeNativeGestureBack,
  bridgePostRequest,
  bridgeTrackEvent,
  pullUpPayment,
};

// productId
export const PRODUCT_ID = {
  JP: 601,
  BR: 510,
  MX: 247,
  CR: 727,
  CO: 723,
  DO: 721,
  CL: 724,
  PE: 725
}

import track from '@/common/js/track';

/** @desc 项目API请求监控埋点 */
export const apiMonitorLog = (response) => {
  try {
    if (response && +response.status === 200) {
      const { config, data } = response;
      const {
        errno,
        traceId = '',
        trace_id = '',
        requestId = '',
        time = '',
      } = data;

      track('sailing_c_api_monitor_bt', 'api请求上报', {
        request_errno: errno,
        request_path: config.url,
        trace_id: traceId || trace_id,
        page_url: window.location.href,
        request_id: requestId,
        request_time: time,
      }, null);
    }
  } catch (err) {
    console.log(err);
  }
};


/** 
 * @desc 项目通用监控埋点 
 * @omega https://omega.intra.didiglobal.com/ba/point/detail?pointId=1075372
 * */
export const omegaH5Monitor = (obj) => {
  try {
    track('sailing_c_h5_monitor_bt', '核心流程通用监控埋点', {
      page_url: window.location.href,
      referrer: document.referrer,
      ...obj
    }, null);
  } catch (err) {
    console.log(err);
  }
};

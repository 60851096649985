// appstore_URL
export const PC_APPSTORE_URL = {
  'MX': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853',
  'BR': 'https://app.appsflyer.com/id1480806762?pid=99Food%20PC%20Web%20iOS',
  'JP': 'https://go.onelink.me/qBSw/4ba18e05',
  'CR': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853',
  'CO': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853',
  'DO': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853',
  'CL': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853',
  'PE': 'https://apps.apple.com/cn/app/didi-food-food-delivery/id1434256853'
}
// googleplay_URL
export const PC_GOOGLEPLAY_URL = {
  'MX': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer',
  'BR': 'https://app.appsflyer.com/com.xiaojukeji.didi.brazil.customer?pid=99Food%20PC%20Web%20Android',
  'JP': 'https://go.onelink.me/tRoF/1fe37e29',
  'CR': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer',
  'CO': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer',
  'DO': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer',
  'CL': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer',
  'PE': 'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer'
}

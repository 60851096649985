const topic = {
  path: '/topic',
  name: 'topic',
  meta: {
    title: 'topic',
  },
  component(resolve) {
    require.ensure([], () => resolve(require('../page/topic/index')));
  },
};
export default [topic];

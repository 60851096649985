// 针对c-h5.didi-food.com/common/config接口mock的公参，后端不做内容校验，仅保证参数有即可
export const requireParams = {
  appVersion: '0.0.1',
  versionCode: 0,
  clientType: 0,
  bizId: 375,
  cityId: 0,
  suuid: 0,
  deviceId: 0,
  timestamp: 0,
  poiId: 0,
  poiCityId: 0,
  lat: 0,
  lng: 0,
  poiLat: 0,
  poiLng: 0,
  imei: '0',
  locale: 'en-US'
}

const Bill = {
  path: "/bill",
  name: "bill",
  meta: {
    title: "bill",
  },
  component: () =>
    import(
      /* webpackChunkName: "order-bill" */
      "../page/bill/index"
    ),
};
export default [Bill];

import store from './store'
// import { bridgeMonitor } from './common/js/bridgeMonitor'
import { util } from '@didi/soda-common'
const { env } = util
export function init () {
  return new Promise(async (resolve) => {
    if(env.inSoda){
      // 获取公参并存入store
      await store.dispatch('getGlobalParams')
      // 获取开国城市并存入store
      if (env.inNative) {
        store.dispatch('GET_CITY_LIST')
      // 判断是否是客户端
      }
      // 获取国家并存入store
      await store.dispatch('GET_COUNTRY')
    }
   
    const { country } = store.state
    // 巴西使用99主题
    if (country === 'BR') {
      window.document.documentElement.setAttribute('data-theme', '99')
      import('./common/stylus/99-theme.styl')
      // 墨西哥使用didi主题
    } else if (country === 'MX') {
      window.document.documentElement.setAttribute('data-theme', 'didi')
      import('./common/stylus/didi-theme.styl')
      // 使用didi主题兜底
    } else {
      window.document.documentElement.setAttribute('data-theme', 'didi')
      import('./common/stylus/didi-theme.styl')
    }

    // 埋点监控sdk初始化
    // await bridgeMonitor()

    resolve()
  })
}

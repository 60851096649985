import { request } from '@/api/common'
// import { requireParams } from '@/common/const/api-require-param'
import apis from '@/common/config/host'

const GET_FEEDBACK_INFO = apis.cApiDomain + '/complaint/missFoodInfo'
const POST_MISS_FOOD_DATA = apis.cApiDomain + '/complaint/missFoodV2'
const POST_NOTIFY_RIDER = apis.cApiDomain + '/complaint/notifyRider'

// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=410319684
export const getMissFoodInfo = function (data) {
  return request.post(GET_FEEDBACK_INFO, data)
}
// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=411047990
export const postMissFoodData = function (data) {
  return request.post(POST_MISS_FOOD_DATA, data)
}
// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=413981976
export const notifyRiderMissFood = function (data) {
  return request.post(POST_NOTIFY_RIDER, data)
}

/**
 * @Author: wangdaohan
 * @Date:   2018-03-23T16:40:46+08:00
 * @Email:  wangdaohan@didichuxing.com
 * @Filename: DDBridge.js
 * @Last modified by:   wangdaohan
 * @Last modified time: 2018-05-10T15:28:23+08:00
 */
import { util } from '@didi/soda-common'
import API from '@/api'
let { env } = util
const DDbridge = {}

DDbridge.registerDefaultBridge = function () {
  if (!env.inNative) {
    /* eslint-disable */
    SodaBridgeRegister({
      Soda: {
        getUserInfo (params = {}, callback) {
          callback && callback({})
        },

        getSystemInfo (params = {}, callback) {
          callback && callback({})
        },

        /* eslint-disable */
        getLocationInfo (params = {}, callback) {
          try {
            // 按需加载
            import('@didi/didi-sdk/dist/geo-location').then(({ sdk }) => {
              let { geoLocation } = sdk
              geoLocation({}, (error, result) => {
                // 如果无错误信息 则为定位成功; 否则为定位失败
                if (!error) {
                  callback && callback(result)
                } else {
                  callback && callback({
                    errno: 1,
                    errmsg: error.toString(),
                    data: {}
                  })
                }
              })
            })
          } catch (e) {
            callback && callback({
              errno: 1,
              errmsg: e.toString(),
              data: {}
            })
          }
        }
      }
    })
  }
}


DDbridge.install = function (Vue, options) {
  Vue.mixin({
    methods: {
      SodaBridge (type, options) {
        // 兼容在非soda业务线内的页面 TODO：安卓ua有问题暂不这样处理
        // if (env.inDidiApp && !env.inSoda) {
        //   return Fusion[type](options)
        // }
        return window.SodaBridge(type, options)
      },

      SodaBridgeData (type, options) {
        return window.SodaBridge(type, options).then(pms => {
          return pms.data || {}
        })
      },
      /**
       * [获取活动开关]
       * @param  {[type]}  activityId [description]
       * @return {Promise}            [0: pre; 1:onGoing; 2:finished]
       */
      async getActivityStatus (activityId) {
        try {
          var config = await API.getById({
            id: activityId
          })
        } catch (e) {
        }
        return config.data.data.status
      },

      // 每次埋点时需要执行的函数，用于获取this.userInfo.uid 以及this.systemInfo.getSystemInfo
      async setUserInfo () {
        if (!this.userInfo || !this.userInfo.hasOwnProperty('uid')) {
          this.userInfo = await this.SodaBridgeData('getUserInfo')
        }
        if (!this.systemInfo || !this.userInfo.hasOwnProperty('uid')) {
          this.systemInfo = await this.SodaBridgeData('getSystemInfo')
        }
      },
      /**
       * 修改标题 
       */
      asyncSetTitle (params) {
        const { navi_title, timeout } = params
        // 延时设置标题，处理ios设置不成功问题
        setTimeout(() => {
          // 安卓ua有问题, 暂时用两种方式直接修改title，用于处理不在soda内
          try {
            if (Fusion && Fusion['setTitle']) {
              Fusion['setTitle']({ 'navi_title': navi_title })
            }
          }catch{}
          this.SodaBridge('setTitle', { 'navi_title': navi_title })
        }, timeout)
      }
    },
    computed: {
      params () {
        let paramsString = window.location.search.slice(1)
        var items = paramsString.split('&')
        var result = {}
        items.forEach(item => {
          let temp = item.split('=')
          result[temp[0]] = temp[1]
        })
        return result
      },
      isWechat () {
        return env.inWechat
      },
      isAlipay () {
        return env.inAlipy
      },
      isQQ () {
        return env.inQQ
      },
      isApp () {
        return env.inNative
      },
      // 是否在app的soda tab内, 以下是兼容之前运营配置的soda_from==2写法
      // 2018.4.25 增加soda_from=2表示在app内 但不在外卖tab内，todo: 后续端发版会在外卖tab增加 标示
      // 2018.5.10 soda app内增加soda_from=1表示在soda内 但需要兼容未更新部分
      isSoda () {
        return this.isApp && (this.params.soda_from && this.params.soda_from === '1')
      },
      isWebApp () {
        // this.params.datatype === 'webapp' 标示在webapp快车运营位
        // this.params.fromWebApp === '1' 标示在soda webapp的运营位
        return this.params.fromWebApp === '1' || this.params.datatype === 'webapp'
      },
      isWebAppSoda () {
        return this.isWebApp && this.params.bizId === '379'
      },
      isAndroid () {
        return env.inAndroid
      },
      isIOS () {
        return env.inIOS
      },
      // 是否是在消息号中打开的，主要是用于落地页，ios需要增加isPopToRootPage=1参数
      isMessageNumber () {
        return this.params.isPopToRootPage === '1'
      },
      UA () {
        return this.systemInfo.appKey || window.navigator.userAgent
      },
      source(){
        if (!this.isApp) {
          return 0
        }else if (this.isIOS) {
          return 1
        } else if (this.isAndroid) {
          return 2
        }
      },
      landingpage () {
        if (this.isApp) {
          return 0
        } else if (this.isWechat) {
          return 1
        } else if (this.isAlipay) {
          return 2
        } else if (this.isQQ) {
          return 3
        } else {
          return 10
        }
      }
    }
  })
}

export default DDbridge

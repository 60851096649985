import Vue from "vue";
import App from "./App";
import router from "./router";
import "@/common/js/rem";
import axios from "axios";
import didiaxios from "@didi/dajax/dist/dajax";
import CouponUI from "@didi/soda-c-coupon";
import { init } from "./init";
import store from "./store";
import "@/common/lib/wsgchallenge";
import { apiMonitorLog } from '@/common/js/tools.js'; // API监控埋点
import {
  Style,
  Cell,
  Button,
  RadioGroup,
  CheckboxGroup,
  Loading,
  Scroll,
  Lazyload,
  Toast,
  Locale,
  Dialog,
  Input,
  Textarea,
  TextareaBox,
  TagGroup,
  Tip,
  Theme,
  Progress,
  ScrollNav,
  Alert
} from "@didi/rich-ui";
import "@/common/stylus/main.styl";
import initGlobalFilters from "./filter";
import "@didi/soda-jsbridge";
import DDBridge from "./plugin/DDBridge";
import { getLanguageLong } from "@didi/gettext";
import i18n from "./common/js/i18n";
import vueI18n from "./i18n/locale";
import "./directives/index";
import { SailingPerformance } from "@didi/sailing-data-tools";
import { inCustomerApp } from "@/common/js/utils";

// Registering Our Service Worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

const currentLang = getLanguageLong();
DDBridge.registerDefaultBridge();
Vue.use(CouponUI);
Vue.use(DDBridge);
Vue.use(i18n.plugin);
Vue.prototype.$http = axios;
Vue.prototype.$didihttp = didiaxios;

Vue.use(Style);
Vue.use(Cell);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(RadioGroup);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Scroll);
Vue.use(Input);
Vue.use(Textarea);
Vue.use(TextareaBox);
Vue.use(TagGroup);
Vue.use(Tip);
Vue.use(Theme);
Vue.use(Progress);
Vue.use(ScrollNav);
Vue.use(Alert)
const { country } = store.state;
// 巴西使用99主题
if (country === "BR") {
  Theme.use("99");
  // 墨西哥使用didi主题
} else if (country === "MX") {
  Theme.use("didi");
} else {
  // 使用didi主题兜底
  Theme.use("didi");
}
Vue.use(Lazyload, {
  preLoad: 1,
  attempt: 3, // 尝试计数
  filter: {
    progressive(listener, options) {
      const size = listener.el.getAttribute("crop-size");
      // SODA 图床暂只支持soda-public的服务
      const isSodaPublic = /soda-public/;
      if (size && isSodaPublic.test(listener.src)) {
        listener.src = `${listener.src}!${size}_m`;
      }
    },
  },
});
Vue.use(Locale);
Locale.use(
  currentLang,
  require(`@didi/rich-ui/lib/locale/lang/${currentLang}`)
);
CouponUI.locale.use(
  require(`@didi/soda-c-coupon/src/locale/lang/${currentLang}`).default
);

initGlobalFilters();
didiaxios.interceptors.response.use(
  (response) => {
    apiMonitorLog(response)
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/* eslint-disable no-new */
Vue.config.productionTip = false;
// 获取国家、初始化主题之后再进行实例化操作
/* eslint-disable no-new */
init().then(() => {
  new Vue({
    el: "#app",
    i18n: vueI18n,
    router,
    store,
    template: "<App/>",
    components: {
      App,
    },
  });
  const { version, name } = require("../package");
  const { globalParams } = store.state;
  const { appVersion = "" } = globalParams;
  const sailingPerformance = new SailingPerformance({
    env: process.env.NODE_ENV,
    Omega: window.Omega,
    router: router,
    prefix: name,
    attrs: {
      version,
      app_version: appVersion || '',
      in_customer_app: +inCustomerApp
    }
  })
});
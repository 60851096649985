import { urlParse, checkIsOldVersion } from '@/common/js/utils'
import { requireParams } from '@/common/const/api-require-param'
import getCountryConfig from '@/common/config/country-config'
import { requestHelper } from '@/api/common'
import { util } from '@didi/soda-common'
import Api from '@/api'
const { env } = util

const host = location.host
const is99App = !!host.match('99app.com')
const COUNTRY = is99App ? 'BR' : 'MX'
const brand = is99App ? '99' : 'didi'
const csTelNumber = getCountryConfig.service.serviceNumber(COUNTRY)// 首先根据域名配置默认的客服电话
const csEmail = getCountryConfig.service.serviceEmail(COUNTRY) // 首先根据域名配置默认的客服电话

const state = {
  // 全局消息
  globalMsg: {},
  // 是否上报过首屏加载埋点
  isSubmitLoadingBP: false,
  country: COUNTRY,
  brand,
  csTelNumber,
  csEmail,
  openedCityList: [],
  isRightTitleBtn: false, // 沉浸式头部右侧是否可配置按钮title
  isSupportHurdle: false, // 是否支持沉浸式
  // 客户端透传过来的参数
  globalParams: {}
}

const getters = {}

const actions = {
  /**
   * 异步获取country，用于 DiDi Food 多国差异化处理
   * 第一步：获取 url 中的 poiCountryCode（收货地址反解）若没有则获取 countryCode（真实定位反解）
   * 第二步：通过 getGlobalParams 获取 poiCountryCode（收货地址反解）若没有则获取 countryCode（真实定位反解）
   * 第三步：
   *   - 通过 bridge（独立端用getGlobalParams、嵌入端用Fusion.getLocationInfo） 获取真实定位 {lat, lng}
   *   - 请求 c-api common/config 接口反解 country
   * 若以上步骤均失败，默认取墨西哥 country: MX
  */
  async GET_COUNTRY ({ commit }, params) {
    let country = COUNTRY
    const QUERY_PARAMS = urlParse()
    // 第一步：先从 URL query 参数中获取 country 参数
    const COUNTRY_FROM_URL = QUERY_PARAMS['poiCountryCode'] || QUERY_PARAMS['countryCode'] // 端上确定后，换成最终的参数名
    // 第二步：如果从URL获取失败，则通过bridge获取
    if (COUNTRY_FROM_URL) {
      country = COUNTRY_FROM_URL
    } else {
      let globalParams = {}
      try {
        // 只在独立端内可以通过bridge获取country
        const RES = await SodaBridge('getGlobalParams', 'badcase') || {}
        globalParams = RES.data
      } catch (e) {}
      const COUNTRY_FROM_BRIDGE = globalParams['poiCountryCode'] || globalParams['countryCode']
      // 第三步：如果bridge获取country也失败，则通过 bridge 获取定位，请求接口反解得到country
      if (COUNTRY_FROM_BRIDGE) {
        country = COUNTRY_FROM_BRIDGE
      } else {
        let position = { lat: '', lng: '' }
        // 独立端先获取poiLat及poiLng
        let { poiLat, poiLng, lat, lng } = globalParams
        if (poiLat && poiLng) {
          position.lat = poiLat
          position.lng = poiLng
        } else if (lat && lng) {
          position.lat = lat
          position.lng = lng
        } else {
          // 再通过fusion bridge 获取一次定位（比如在嵌入端容器内）
          try {
            const LOCATION_INFO = await new Promise((resolve, reject) => {
              Fusion.getLocationInfo({}, function (response) {
                resolve(response)
              })
            })
            position.lat = LOCATION_INFO.lat
            position.lng = LOCATION_INFO.lng
          } catch (e) {}
        }
        // 访问capi接口进行反解
        try {
          if (position.lat && position.lng) {
            const ADDRESS_INFO = await Api['getAddress']({ ...requireParams, poiLat: position.lat, poiLng: position.lng }) // 接口定义经度入参为poiLng，维度入参为poiLat
            country = (ADDRESS_INFO['data'] && ADDRESS_INFO['data']['country']) || country
          }
        } catch (e) {}
      }
    }
    // 如果反解出国家码是BR，则重置为MX，避免出现didi品牌下有99相关的样式及信息
    if (country === 'BR') {
      country = COUNTRY
    }
    // 设置国家码
    commit('UPDATE_COUNTRY', country)
    // 设置客服电话
    commit('UPDATE_CS_TEL_NUMBER', country)
    // 设置客服邮箱
    commit('serviceEmail', country)
  },
  // 获取已开城城市列表
  async GET_CITY_LIST ({ commit, state }, params) {
    let url = Api.feedUnOpened
    // 闪送开城列表复用外卖的开城
    // if (state.globalParams.sailingBizLine == 2) {  // eslint-disable-line
    //   url = Api.entregaCityList
    // }
    await requestHelper(url, {}).then(res => {
      if (res && res.errno === 0) {
        commit('UPDATE_OPENED_CITY_LIST', res.data && res.data.cities)
      }
    })
  },

  async versionControl ({commit, state}) {
    // 获取公参
    const globalParams = await SodaBridge('getGlobalParams', 'badcase') || {data: {}}
    const { appVersion } = globalParams && globalParams.data // 当前版本号
    let hurdleVersion = '' // 2020.7.14新增 通栏版本号
    let hurdleBtnVersion = '' // 商家券新增通栏右侧定义文字按钮版本号
    if (appVersion) {
      // 是否为嵌入端的判断
      if (env.inEmbedCustomerApp) {
        // 判断此版本是否支持通栏样式
        hurdleVersion = state.brand === '99' ? '6.14.2' : '7.1.98'
        hurdleBtnVersion = state.brand === '99' ? '6.16.8' : '7.2.24'
      } else {
        // 判断此版本是否支持通栏样式
        hurdleVersion = '1.2.34'
        hurdleBtnVersion = '1.2.58'
      }
      commit('UPDATE_IS_SUPPORT_HURDLE', !checkIsOldVersion(appVersion, hurdleVersion))
      commit('UPDATE_IS_RIGHT_TITLE_BUTTON', !checkIsOldVersion(appVersion, hurdleBtnVersion))
    } else {
      commit('UPDATE_IS_SUPPORT_HURDLE', false)
      commit('UPDATE_IS_RIGHT_TITLE_BUTTON', false)
    }
  },
  // 获取公参信息
  async getGlobalParams ({commit}) {
    try {
      const globalParams = await SodaBridge('getGlobalParams', 'badcase') || {data: {}}
      const params = globalParams && globalParams.data // 当前版本号
      commit('UPDATE_GLOBAL_PARAMS', params)
    } catch (e) {
      console.log(e)
    }
  }
}

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  UPDATE_GLOBAL_PARAMS (state, payload) {
    state.globalParams = payload
  },
  /**
   * 展示全局消息
   */
  SHOW_MSG (state, payload) {
    const DEFAULT = {
      show: true,
      type: 'error',
      txt: '',
      time: 3000
    }
    const msg = { ...DEFAULT, ...payload }
    state.globalMsg = msg
  },
  /**
   * 更新首次进入页面时间
   */
  UPDATE_LOADING_BP (state, payload) {
    state.isSubmitLoadingBP = true
  },
  /**
   * 更新国家参数
   */
  UPDATE_COUNTRY (state, country) {
    if (state.country !== 'BR') {
      state.country = country
    }
  },
  /**
   * 更新客服电话
   */
  UPDATE_CS_TEL_NUMBER (state, country) {
    if (state.country !== 'BR') {
      state.csTelNumber = getCountryConfig.service.serviceNumber(country)
    }
  },
  /**
   * 更新客服邮箱
   */
  serviceEmail (state, country) {
    if (state.country !== 'BR') {
      state.csEmail = getCountryConfig.service.serviceEmail(country)
    }
  },
  UPDATE_OPENED_CITY_LIST (state, payload) {
    state.openedCityList = payload
  },
  /**
   * 更新沉浸式是否支持配置文字按钮
   */
  UPDATE_IS_RIGHT_TITLE_BUTTON (state, payload) {
    state.isRightTitleBtn = payload
  },
  /**
   * 更新沉浸式
   */
  UPDATE_IS_SUPPORT_HURDLE (state, payload) {
    state.isSupportHurdle = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

const validCouponInfo = {
  path: "/user/validCouponInfo",
  name: "validCouponInfo",
  meta: {
    title: "validCouponInfo",
  },
  component: () =>
    import(
      /* webpackChunkName: "validCouponInfo" */ "../page/user/validCoupon/index.vue"
    ),
};

const invalidCouponInfo = {
  path: "/user/invalidCouponInfo",
  name: "invalidCouponInfo",
  meta: {
    title: "invalidCouponInfo",
  },
  component: () =>
    import(
      /* webpackChunkName: "validCouponInfo" */ "../page/user/invalidCoupon/index.vue"
    ),
};
// DiDi Club专属商家优惠 special
const specialCouponInfo = {
  path: "/user/specialCouponInfo",
  name: "specialCouponInfo",
  meta: {
    title: "specialCouponInfo",
  },
  component: () =>
    import(
      /* webpackChunkName: "validCouponInfo" */ "../page/user/specialCoupon/index.vue"
    ),
};
export default [validCouponInfo, invalidCouponInfo, specialCouponInfo];

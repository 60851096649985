const soloDining = {
  path: "/soloDining",
  name: "soloDining",
  meta: {
    title: "soloDining",
  },
  component(resolve) {
    require.ensure([], () => resolve(require("../page/soloDining/index")));
  },
};
export default [soloDining];

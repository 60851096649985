import { bridgePromisify, BridgeMonitor, Soda } from './utils'

export const getGlobalParams = bridgePromisify(
  Soda.getGlobalParams,
  undefined,
  true
)
export const setTitle = bridgePromisify(Soda.setTitle)
export const updateNav = bridgePromisify(Soda.updateNav)
export const openPage = bridgePromisify(Soda.openPage)
export const closePage = bridgePromisify(Soda.closePage)
export const goBack = bridgePromisify(Soda.goBack)
export const getUserInfo = bridgePromisify(Soda.getUserInfo)
export const requestLogin = bridgePromisify(Soda.requestLogin)

export const getLocationInfo = bridgePromisify(Soda.getLocationInfo)
export const updateGlobalCart = bridgePromisify(Soda.customer.updateGlobalCart)

export const registerTrigger = bridgePromisify(Soda.customer.registerTrigger)
export const unregisterTrigger = bridgePromisify(
  Soda.customer.unregisterTrigger
)
export const updateBill = bridgePromisify(Soda.customer.updateBill)
export const guideParams = bridgePromisify(Soda.customer.guideParams)
export const addCartEvent = bridgePromisify(Soda.customer.addCartEvent)
export const request = bridgePromisify(
  Soda.customer.request,
  'Soda.customer.request'
)
export const bridgeTrackEvent = bridgePromisify(Soda.customer.trackEvent)

// 安卓 是否关闭手势返回 true关闭 false开启
// *ios 不建议fe控制手势动作 提出方案在容器内禁止侧滑
export const setBackPressListener = bridgePromisify(Soda.setBackPressListener)

export const triggerWebViewLifecycleEvent = new BridgeMonitor(
  'customer',
  'triggerWebViewLifecycleEvent'
)
export const triggerBillEvent = new BridgeMonitor(
  'customer',
  'triggerBillEvent'
)
export const triggerAddressChanged = new BridgeMonitor(
  'customer',
  'triggerAddressChanged'
)

export const pullUpPayment = function (params, callback) {
  try {
    window.Fusion.loadModule('payment').universalPay(params, callback)
  } catch {}
}

export const getUrlParams =  (search) =>{
    const values = search.split('?')
    if (values && values.length && values[1]) {
      return values[1].split('&').reduce((params, keyValue) => {
        return Object.assign(params, {
          [keyValue.split('=')[0]]: keyValue.split('=')[1] || ''
        })
      }, {})
    }
    return {}
  }

import development from './dev'
import testing from './test'
import production from './prod'
const env = process.env.NODE_ENV || 'development'

const envs = {
  development,
  testing,
  production
}

const defaultOpt = production

const envOpt = envs[env]

export default {
  ...defaultOpt,
  ...envOpt
}

import { request } from '@/api/common'
import apis from '@/common/config/host'

const GET_WALLET_COUPONS = apis.actApiDomain + '/act-api/api/sidebar/validCouponsV2'
// const GET_WALLET_COUPONS = PAY_MIS_URL + '/foundation/coupon/v1/walletinterface/getwalletcoupons'
const SIDEBAR_EXCHANGE_COUPONS = apis.actApiDomain + '/act-api/api/code/sidebarRedeem'
const SIDEBAR_EXCHANGE_RESULT = apis.actApiDomain + '/act-api/api/code/redeemResult'
const APOLLO_AB_CONFIG = apis.actApiDomain + '/act-api/api/sidebar/route'

export const getAllCoupons = function (data) {
  return request.post(GET_WALLET_COUPONS, data)
}

export const sidebarExchangeCoupons = function (data) {
  return request.post(SIDEBAR_EXCHANGE_COUPONS, data)
}

export const getCouponSuccessInfo = function (data) {
  return request.post(SIDEBAR_EXCHANGE_RESULT, data)
}

export const getApolloABConfig = function (data) {
  return request.post(APOLLO_AB_CONFIG, data)
}

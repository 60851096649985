import store from '@/store'
import { getLocale } from '@didi/gettext'
import didiaxios from '@didi/dajax/dist/dajax'
import i18n from '@/common/js/i18n'
import getCountryConfig from '@/common/config/country-config'
import { util } from '@didi/soda-common'
import { apiErrnoError } from '@/common/js/trackApiError'
const { env } = util

// 添加一些公共参数
export async function getCommonParams () {
  // 端内请求从bridge获取公共参数
  let globalParams = {}

  if (env.inNative) {
    // 检查getGlobalParam是否可用，在旧版嵌入端是不可用的
    if (Soda.getGlobalParams && env.inSoda) {
      const globalParamsRes = await SodaBridge('getGlobalParams', 'badcase') || {}
      globalParams = globalParamsRes.data
    }
  }
  const locale = getLocale()
  return {
    ...globalParams,
    locale
  }
}

function setRequest (method, config) {
  if (typeof config === 'string') {
    config = Object.assign({
      method,
      url: config
    }, arguments[2])
  }
  return new Promise(async (resolve, reject) => {
    let params = config.params || {}
    let data = config.data || {}
    let commonParams = await getCommonParams()
    let targetParams = Object.assign(commonParams, params, data)
    if (config.method === 'post') {
      config.data = targetParams
    } else {
      config.params = targetParams
    }
    didiaxios.request({
      url: config.url,
      method: config.method,
      emulateJSON: true, // 是否使用application/x-www-form-urlencoded来提交
      needEncrypt: true, // 是否加签名
      headers: config.headers || {}, // 自定义header
      params: config.params,
      body: targetParams
    }).then(response => {
      if (response.status !== 200) {
        reject(response)
      }
      return response
    }).then(data => {
      if (!data || data.errno) {
        reject(data)
      }
      resolve(data)
    }).catch(error => {
      reject(error)
    })
  })
}

function setBffRequest (method, config) {
  if (typeof config === 'string') {
    config = Object.assign({
      method,
      url: config
    }, arguments[2])
  }
  return new Promise(async (resolve, reject) => {
    let data = config.data || {}
    let globalParams = await getCommonParams()
    let countryCode = globalParams.countryCode || globalParams.poiCountryCode
    let targetParams = {
      common: {
        lng: globalParams.lng,
        lat: globalParams.lat,
        ticket: globalParams.token,
        terminal_id: Number(globalParams.terminalId),
        app_version: globalParams.appVersion,
        product_id: getCountryConfig.productId.PRODUCT_ID(countryCode),
        biz_type: Number(globalParams.bizId),
        lang: globalParams.locale,
        city_id: Number(globalParams.poicityid),
        location_cityid: Number(globalParams.poiCityId),
        location_country: countryCode,
        networkType: globalParams.networkType,
        client_type: globalParams.clientType,
        datatype: '' + globalParams.dataType,
        data_type: '' + globalParams.dataType,
        mobileType: globalParams.deviceType,
        platform_type: env.isIOS ? 1 : 2,
        device_info: {
          suuid: globalParams.suuid,
          imei: globalParams.imei,
          deviceid: globalParams.deviceId,
          osType: '' + globalParams.osType,
          osVersion: '' + globalParams.osVersion
        }
      },
      ...data
    }
    // bff要求取不到的参数直接不传
    Object.entries(targetParams.common).forEach(entry => {
      let [key, value] = entry
      if (!value) delete targetParams.common[key]
    })
    didiaxios.request({
      url: config.url,
      method: config.method,
      emulateJSON: false, // 是否使用application/x-www-form-urlencoded来提交
      needEncrypt: true, // 是否加签名
      headers: config.headers || {}, // 自定义header
      params: config.params,
      body: targetParams
    }).then(response => {
      if (response.status !== 200) {
        reject(response)
      }
      return response
    }).then(data => {
      if (!data || data.errno) {
        reject(data)
      }
      resolve(data)
    }).catch(error => {
      reject(error)
    })
  })
}

// 封装 axios，添加一系列公共参数与公共请求头
export const request = ['get', 'post'].reduce((api, method) => {
  api[method] = setRequest.bind(null, method)
  return api
}, {})

// bff只接受post请求
export const bffRequest = ['post'].reduce((api, method) => {
  api[method] = setBffRequest.bind(null, method)
  return api
}, {})

export async function requestHelper (request, ...args) {
  try {
    const result = await request.apply(null, args)
    const data = result && result.data ? result.data : result
    if (data && !data.errno) {
      return data
    } else {
      apiErrnoError({
        page_url: window.location.href,
        trace: data
      })
      return data || {}
    }
  } catch (err) {
    apiErrnoError({
      page_url: window.location.href,
      trace: err
    })
    store.commit('SHOW_MSG', {
      type: 'error',
      txt: i18n._('网络不佳，请检查您的网络')
    })
  }
}

import { request } from '@/api/common'
import apis from '@/common/config/host'
const CentregaRules = apis.cApiDomain + '/entrega/serviceDesc'
const CentregaOrderPreCancel = apis.entregaApiDomain + '/entrega/orderPreCancel'
const CentregaOrderCancel = apis.entregaApiDomain + '/entrega/orderCancel'

// 获取闪送服务规则信息
export const getCentregaRules = function (data) {
  return request.post(CentregaRules, data)
}

// 获取闪送预判则信息
export const getCentregaOrderPreCancel = function (data) {
  return request.post(CentregaOrderPreCancel, data)
}

// 获取闪送确定取消
export const getCentregaOrderCancel = function (data) {
  return request.post(CentregaOrderCancel, data)
}

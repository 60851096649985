const defaultPic = 'https://pt-starimg.didistatic.com/static/starimg/img/NaoVzyqXQX1648107890585.png'

// 闪送服务规则头图
export const headImg = {
  BR: 'https://pt-starimg.didistatic.com/static/starimg/img/LaR275lR5D1655869341770.png',
  MX: defaultPic,
  JP: defaultPic,
  CR: defaultPic,
  CO: defaultPic,
  DO: defaultPic,
  CL: defaultPic
}

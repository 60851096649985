import { VueJed, getLanguageLong } from '@didi/gettext'
// import enUS from '../../locale/en-US/soda-c-i18n.po'
// import esMX from '../../locale/es-MX/soda-c-i18n.po'
// import zhCN from '../../locale/zh-CN/soda-c-i18n.po'
// import ptBR from '../../locale/pt-BR/soda-c-i18n.po'
// import jaJP from '../../locale/ja-JP/soda-c-i18n.po'

import enUS from '../../i18n/en-US'
import esMX from '../../i18n/es-MX'
import esCO from '../../i18n/es-CO'
import zhCN from '../../i18n/zh-CN'
import ptBR from '../../i18n/pt-BR'
import jaJP from '../../i18n/ja-JP'
import es419 from '../../i18n/es-419'

const map = {
  'zh-CN': zhCN,
  'en-US': enUS,
  'es-MX': esMX,
  'es-CO': esCO,
  'pt-BR': ptBR,
  'ja-JP': jaJP,
  'es-419': es419
}
const lang = getLanguageLong()

export default new VueJed(map[lang])

import Vue from 'vue'
import { url2localProtocol, dateFormat } from '@/common/js/utils'

export default function initGlobalFilters () {
  // url转为本地的协议
  Vue.filter('url2localProtocol', (url = '') => {
    return url2localProtocol(url)
  })
  // 时间从 YYYY-MM-DD 转为 MM-DD-YYYY
  Vue.filter('dateFilter', (dateStr = '') => {
    return dateFormat(dateStr)
  })
}

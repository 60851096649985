import {getUrlParams} from '@/common/js/getUrlParams.js'
const MerchantCouponInfo = {
  path: "/merchantCouponInfo",
  name: "MerchantCouponInfo",
  meta: {
    title: "merchantCouponInfo",
  },
  component: () => {
    const newVersion = getUrlParams(location.href)?.marketingVersion * 1
    // 版本2、3的时候都走2.0版本，3的时候券组件升级为最新的样式
    if(newVersion == 2 || newVersion == 3) {
      return import(
        /* webpackChunkName: "merchantCouponInfo" */ "../page/merchantsNew/couponInfo.vue")
    }
    return import(
      /* webpackChunkName: "merchantCouponInfo" */ "../page/merchants/couponInfo.vue")
  },
};

export default [MerchantCouponInfo];

// import { requireContextApi } from '@/common/js/requireContext'
// const ctx = require.context('./list', true, /\.js$/)
// export default requireContextApi(ctx)
import * as brandText from './list/brand-text'
import * as downloadUrl from './list/download-url'
import * as orderPic from './list/order-pic'
import * as rulesHeadPic from './list/entrega-rules-head'
import * as rulesValuationPic from './list/entrega-rules-valuation'
import * as productId from './list/product-id'
import * as service from './list/service'
import * as walletPaymentType from './list/wallet-payment-type'
import * as entrega from './list/entrega'

function configMixin (targetConfig = {}) {
  const defaultCountry = 'MX'
  const finalConfig = {}
  Object.keys(targetConfig).map(key => {
    finalConfig[key] = (targetCountry) => {
      return targetConfig[key][targetCountry] || targetConfig[key][defaultCountry]
    }
  })
  return finalConfig
}

export default {
  brandText: configMixin(brandText),
  downloadUrl: configMixin(downloadUrl),
  orderPic: configMixin(orderPic),
  rulesHeadPic: configMixin(rulesHeadPic),
  rulesValuationPic: configMixin(rulesValuationPic),
  productId: configMixin(productId),
  service: configMixin(service),
  walletPaymentType: configMixin(walletPaymentType),
  entrega: configMixin(entrega)
}

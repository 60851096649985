import { request } from "@/api/common";
import apis from "@/common/config/host";
const SOLO_DINING = apis.cApiDomain + '/feed/specialTopicIndex'
export const soloDining = function (params) {
  // return request.post(
  //   "https://mock.xiaojukeji.com/mock/12440/feed/rankIndex_1681465246265_1691392564159",
  //   params
  // );
  return request.post(SOLO_DINING, params);
};

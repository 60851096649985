import track from '@/common/js/track'
import { observer } from './index'
import {
  LOG_DATA_MAP, // 埋点数据
  LOG_SHOW_MAP // 已上报数据
} from './tools'

/** @desc 曝光策略 */
export function logStrategy (target) {
  const id = target.getAttribute('data-id') || ''
  const trackData = LOG_DATA_MAP[id] || {}
  const { eventId, eventLabel = '', attrs = {} } = trackData

  if (!eventId) {
    observer.unobserve(target)
    console.warn('埋点数据格式异常：缺少必要参数')
    return
  }

  // 已上报埋点，移除观察
  if (LOG_SHOW_MAP[id]) {
    observer.unobserve(target)
    return
  }

  // 数据上报
  track(eventId, eventLabel, {
    ...attrs
  }, null)

  // 移除观察 && 记录已上报状态
  observer.unobserve(target)
  LOG_SHOW_MAP[id] = true
}

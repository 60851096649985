const NotAvailable = {
  path: '/abnormal/not-available',
  name: 'not-available',
  meta: {
    title: '当前城市暂未开通'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/not-available')))
  }
}
const NoShop = {
  path: '/abnormal/no-shop',
  name: 'not-available',
  meta: {
    title: '附近商家筹备中'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/no-shop')))
  }
}
const PreHeat = {
  path: '/abnormal/pre-heat',
  name: 'pre-heat',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/pre-heat')))
  }
}
const PreAvailable = {
  path: '/abnormal/pre-available',
  name: 'pre-available',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/pre-available')))
  }
}
const NotAvailableArea = {
  path: '/abnormal/not-available-area',
  name: 'not-available-area',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/not-available-area')))
  }
}
const DangerousArea = {
  path: '/abnormal/dangerous-area',
  name: 'dangerous-area',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/dangerous-area')))
  }
}

const NonactivatedArea = {
  path: '/abnormal/nonactivated-area',
  name: 'nonactivated-area',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/nonactivated-area')))
  }
}

const NoMerchant = {
  path: '/abnormal/no-merchant',
  name: 'no-merchant',
  meta: {
    title: ''
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/abnormal/no-merchant')))
  }
}

export default [NotAvailable, NoShop, PreHeat, PreAvailable, NotAvailableArea, DangerousArea, NonactivatedArea, NoMerchant]

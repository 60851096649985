/**
 * @Author: wangdaohan
 * @Date:   2018-03-08T15:31:02+08:00
 * @Email:  wangdaohan@didichuxing.com
 * @Filename: rem.js
 * @Last modified by:   wangdaohan
 * @Last modified time: 2018-03-08T18:13:03+08:00
 */
(function (doc, win) {
  const docEl = doc.documentElement
  const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
  const recalc = function () {
    var clientWidth = docEl.clientWidth
    if (!clientWidth) { return }
    docEl.style.fontSize = (clientWidth / 16) + 'px'
  }

  if (!doc.addEventListener) { return }
  win.addEventListener(resizeEvt, recalc, false)
  recalc()
  // hack兼容某些特殊机型
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)

import currencyType from '@/common/config/currency-type'
import { getLocale, getLanguageLong } from '@didi/gettext'
import getCountryConfig from '@/common/config/country-config'
import store from '@/store'
import get from 'lodash/get'
export function url2localProtocol (url = '') {
  url = url || ''
  const match = url.match(/^(http|https):(.*)/)
  if (match) {
    return window.location.protocol + match[2]
  } else {
    return url
  }
}

export function getSchemeName () {
  const { country } = store.state
  // return 'oneTravel' // IOS bug，独立端还未上线，先写死
  // return 'gsodacustomer'
  // 后补 2019-04-08更新   国际版独立端协议头gsodacustomer 嵌入端协议头globalOneTravel
  // 再后补 2019-11-29更新  新增99独立端与嵌入端
  let schemes = {
    'DiDi': {
      'Food': 'gsodacustomer',
      'Travel': 'globalOneTravel'
    },
    '99': {
      'Food': 'bsodacustomer',
      'Travel': 'taxis99OneTravel'
    }
  }
  let schemePath = `${country === 'BR' ? '99' : 'DiDi'}.${inCustomerApp ? 'Food' : 'Travel'}`

  return get(schemes, schemePath, 'gsodacustomer')
}

export function debounce (func, wait, immediate) {
  let timeout
  let result

  const later = function (context, args) {
    timeout = null
    if (args) {
      result = func.apply(context, args)
    }
  }

  const debounced = function (...args) {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(later, wait)
      if (callNow) {
        result = func.apply(this, args)
      }
    } else {
      timeout = setTimeout(() => {
        later(this, args)
      }, wait)
    }

    return result
  }

  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export function formatCoupon (couponList) {
  return couponList.map(coupon => {
    return {
      name: coupon.batch_name, // 券名称
      couponId: coupon.couponid, // 券Id
      couponType: +coupon.coupon_type, // 券类型 3:抵扣券 100:折扣券
      couponAmount: coupon.coupon_amount, // 抵扣券最高抵扣金额
      couponAmountSplit: coupon.rl_coupon_amount_split, // 券面额及货币符号结构，日本改造后启用 接口文档：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=279139099
      expireTimeDisplay: coupon.rl_expire_time_display, // 过期时间，后端返回的可直接展示的时间字符串
      discount: coupon.discount, // 折扣券-折扣数
      remark: coupon.remark, // 券说明
      rlCustomLogo: coupon.rl_custom_logo, // 商家券logo
      currency: currencyType['' + coupon.currency], // 已废弃，国家码，日本改造后已废弃
      availableStatus: +coupon.status === 2 && +coupon.rl_expired === 0 ? 1 : 2, // status 券状态 见wiki: http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=104837020; rl_expired 代表券是否过期 1:已过期 0:未过期
      isNew: coupon.isNew, // 新版优惠券新增 是否为新手券 0:非新手券 1:新手券
      minCost: coupon.minCost || 0, // 新版优惠券新增 最小使用金额
      minCostSplit: coupon.minCostSplit || {}, // 新版优惠券新增 最小使用金额
      expireTime: coupon.expireTimeFmt, // 新版优惠券新增，过期时间。已精确为当地时间
      maxAmount: coupon.max_amount || 0, // 新版优惠券新增，折扣券展示用，折扣券最高可抵扣金额
      maxAmountSplit: coupon.maxAmountSplit || {}, // 新版优惠券新增，折扣券展示用，折扣券最高可抵扣金额
      useRuleCity: coupon.useRuleCity || [], // 新版优惠券新增，城市维度使用限制
      useRuleWeekDay: coupon.useRuleWeekDay || [], // 新版优惠券新增，星期维度使用限制
      useRuleTimeRange: coupon.useRuleTimeRange || [], // 新版优惠券新增，券使用时间限制。精确到时分
      useRuleDate: coupon.useRuleDate || [], // 新版优惠券新增，券使用日期限制
      payTypeList: coupon.payTypeList || [], // 新版优惠券新增，支付方式列表
      costType: coupon.costType || 0, // 新版优惠券新增，抵扣类型
      shopNameList: coupon.shopNameList || [], // 新版优惠券新增，可使用店铺列表
      cardBinList: coupon.cardBinList || []
    }
  })
}

/**
 * 检查 value 是否为空
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 * @example
 * isEmpty(null); // => true
 * isEmpty(false); // => true
 * isEmpty(0); // => true
 * isEmpty([1, 2, 3]); // => false
 * isEmpty({ 'a': 1 }); // => false
 */
export function isEmpty (v) {
  switch (typeof v) {
    case 'undefined':
      return true
    case 'string':
      if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) {
        return true
      }
      break
    case 'boolean':
      if (!v) {
        return true
      }
      break
    case 'number':
      if (v === 0 || isNaN(v)) {
        return true
      }
      break
    case 'object':
      if (v === null || v.length === 0) {
        return true
      }
      for (var i in v) {
        return false
      }
      return true
  }
  return false
}

export function params () {
  let paramsString = window.location.search.slice(1)
  var items = paramsString ? paramsString.split('&') : []
  var result = {}
  items.forEach(item => {
    let temp = item.split('=')
    result[temp[0]] = temp[1]
  })
  return result
}

/**
 * 检查当前版本是否为旧版本，版本号需符合 x.x.x 格式
 * @param {*} curVersion 当前版本号
 * @param {*} baseVersion 基准版本号
 */
export function checkIsOldVersion (curVersion, baseVersion) {
  const curVersionArr = curVersion.split('.')
  const baseVersionArr = baseVersion.split('.')

  const minL = Math.min(curVersionArr.length, baseVersionArr.length)
  let pos = 0
  let diff = 0
  while (pos < minL) {
    diff = parseInt(curVersionArr[pos]) - parseInt(baseVersionArr[pos])
    if (diff !== 0) {
      break
    }
    pos++
  }

  return diff < 0
}

export function dateFormat (dateStr) {
  const locale = getLocale()
  let dateStrRes = ''
  if (locale === 'zh-CN') {
    dateStrRes = dateStr
  } else if (locale === 'en-US') {
    let dateArr = dateStr.split('-')
    let tempItem = dateArr[0]
    dateArr[0] = dateArr[1]
    dateArr[1] = dateArr[2]
    dateArr[2] = tempItem
    dateStrRes = dateArr.join('-')
  } else if (locale === 'es-MX' || locale === 'pt-BR') {
    let dateArr = dateStr.split('-')
    let tempItem = dateArr[0]
    dateArr[0] = dateArr[2]
    dateArr[2] = tempItem
    dateStrRes = dateArr.join('-')
  }
  return dateStrRes
}

export function getLocaleBrandText (textKey) {
  const { country } = store.state
  return getCountryConfig.brandText.brandText(country)[textKey][getLanguageLong()]
}

/**
 * 解析url参数
 * url 参数 不输入默认解析当前页面的URL参数
 * @example ?id=12345&a=b
 * @return Object {id:12345,a:b}
 */
export function urlParse (url) {
  const search = url || window.location.search
  const reg = /[?&][^?&]+=[^?&]+/g
  const arr = search.match(reg)
  let obj = {}
  if (arr) {
    arr.forEach(item => {
      let tempArr = item.substring(1).split('=')
      let key = decodeURIComponent(tempArr[0])
      let val = decodeURIComponent(tempArr[1])
      obj[key] = val
    })
  }
  return obj
}

/**
 * 拼接货币展示模板
 * 根据后端返回的货币结构split，拼接输出html模板
 * @param {*} split 货币展示结构，包括金额 number，货币符号 symbol，符号展示位置 position
 * @param {*} classSymbol 货币符号span class名
 * @param {*} classNumber 金额span class名
 * @return <span class="symbol">$</span><span class="number">100</span>
 */
export function amountTemplate (data) {
  let resTemplate = ''
  if (data) {
    const { split, clsSymbol, clsNumber } = data
    if (split) {
      const { number, symbol, position } = split // position 货币符号位置 0 货币符号在前 1 货币符号在后
      const symbolFirst = `<span class="${clsSymbol}">${symbol}</span><span class="${clsNumber}">${number}</span>`
      const numberFirst = `<span class="${clsNumber}">${number}</span><span class="${clsSymbol}">${symbol}</span>`
      resTemplate = +position === 0 ? symbolFirst : numberFirst
    }
  }
  return resTemplate
}

/**
 * 处理券面额字段方法
 * 根据后端返回的券面额number，处理成只展示元的形式，如$50.00 => $50
 * @param {*} amount 券面额字段
 */
export function getIntNumFromAmount (amount = '') {
  if (amount) {
    let amountStr = amount.toString()
    let splitSigns = [',', '.']
    // 判断length大于3，且倒数第三位是分割符则进行抹零操作
    if (amountStr.length > 3 && splitSigns.includes(amountStr.slice(-3, -2))) {
      return amountStr.slice(0, -3)
    } else {
      return amountStr
    }
  } else {
    return ''
  }
}

export function checkAppType (globalParams) {
  let typeMap = {
    brand: {
      2: 'Global',
      3: 'BR'
    },
    terminal: {
      1: 'Food',
      2: 'Travel'
    }
  }
  if (globalParams) {
    let { brand, terminalType } = globalParams
    return `${typeMap.brand[brand]}_${typeMap.terminal[terminalType]}`
  }
  return 'unknown'
}

// 判断当前app版本是否为新版购物车
export function isNewCartVersion (globalParams) {
  let { appVersion } = globalParams
  let newCartVersion = {
    // DiDi
    Global_Food: '1.2.54',
    Global_Travel: '7.2.20',
    // 99
    BR_Food: '1.2.54',
    BR_Travel: '6.16.4'
  }
  let appType = checkAppType(globalParams)
  if (appType === 'unknown') {
    console.error('unknown app type')
    return false
  }
  return !checkIsOldVersion(appVersion, newCartVersion[appType])
}

/**
 * 客户端 和 前端 富文本中颜色兼容处理
 * argb 转 rgba 只适用于HEX形式的
 * exp：#CC000000 转为 #000000CC
 */
export function hexARGBGetRGBA(str) {
  if (str.indexOf('#') === 0 && str.length === 9) {
    return `#${str.substring(3)}${str.substring(1, 3)}`;
  } else {
    return str;
  }
}

/**
 * 处理数量兼容单位
 */
export function getCaleResult(curNum, weight, maxWeight, maxNum) {
  let curWeight = +curNum * +weight
  return (curWeight > +maxWeight) || (+curNum === +maxNum) ?
    maxWeight :
    curWeight
}

const inBrowser = typeof window !== 'undefined'
const UA = inBrowser && window.navigator.userAgent.toLowerCase()
const inNative = UA && (/FusionKit/i).test(UA)
export const inCustomerApp = inNative && (/soda.customer/i).test(UA)

const Terms = {
  path: '/user-guide/terms',
  name: 'userGuideTerms',
  meta: {
    title: 'userGuideTerms'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/user-guide/terms-of-use')))
  }
}
const TermsEntrega = {
  path: '/user-guide/terms/entrega',
  name: 'userGuideTermsEntrega',
  meta: {
    title: 'userGuideTermsEntrega'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/user-guide/terms-of-use-entrega')))
  }
}
const PaymentType = {
  path: '/user-guide/wallet/payment/type',
  name: 'paymentType',
  meta: {
    title: 'paymentType'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/user-guide/wallet/payment-type')))
  }
}

const PaymentCash = {
  path: '/user-guide/wallet/payment/cash',
  name: 'cashPayment',
  meta: {
    title: 'cashPayment'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/user-guide/wallet/payment-cash')))
  }
}

const PaymentPos = {
  path: '/user-guide/wallet/payment/pos',
  name: 'posPayment',
  meta: {
    title: 'posPayment'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/user-guide/wallet/payment-pos')))
  }
}

export default [Terms, PaymentType, PaymentCash, PaymentPos, TermsEntrega]

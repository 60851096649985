import { util } from '@didi/soda-common'
import { getGlobalParams } from '@/common/bridge/api.js'
const { env } = util

const url = require('url')

function params() {
  let paramsString = window.location.search.slice(1)
  var items = paramsString ? paramsString.split('&') : []
  var result = {}
  items.forEach((item) => {
    let temp = item.split('=')
    result[temp[0]] = temp[1]
  })
  return result
}

// bridge获取埋点公共参数
async function getCommonParams() {
  let globalParams = {}
  // 端内请求从bridge获取埋点公共参数
  if (env.inNative) {
    // 检查getGlobalParam是否可用，在旧版嵌入端是不可用的
    if (Soda.getGlobalParams) {
      const globalParamsRes = (await getGlobalParams()) || {}
      globalParams = globalParamsRes.data
    }
  }
  return {
    pub_relation_id: globalParams.relationId || -999, // SA 关系ID
    appVersion: globalParams.appVersion,
    app_version: globalParams.appVersion,
    first_activity_id: globalParams.firstActivityId,
    first_channel_id: globalParams.firstChannelId,
    channel_id: globalParams.extChannelId || globalParams.channel,
    sailing_activity_id: globalParams.extActivityId,
    device_type: globalParams.deviceType,
    city_id: globalParams.cityId,
    country: globalParams.countryCode,
    poi_city_id: globalParams.poiCityId,
    internet_type: globalParams?.netWorkType,
    op_system: globalParams?.deviceType,
    pub_location_city_id: globalParams.cityId // todo
  }
}

// 获取当前所属页面
function getCurPageName() {
  let pagePathArr = url.parse(location.href).pathname.slice(1).split('/')
  pagePathArr.shift()
  return {
    current_page: pagePathArr.join('/')
  }
}
export default async function track(event, eventName, options, cb) {
  let globalParams = await getCommonParams()
  window.Omega.trackEvent(
    event,
    eventName,
    {
      ...Object.assign({}, globalParams, params(), getCurPageName(), options)
    },
    null,
    (res) => {
      cb && cb(res)
    }
  )
}

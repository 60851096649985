import { request } from "@/api/common";
import apis from "@/common/config/host";
const BENEFITS_INFO =
  apis.actApiDomain + "/act-api/api/benefitgrowth/getBenefitsInfo";

export const userDiscountStat = function (params) {
  // return request.post(
  //   "https://mock.xiaojukeji.com/mock/12440/common/userDiscountStat",
  //   params
  // );
  return request.post(apis.cApiDomain + "/common/userDiscountStat", params);
};
export const getBenefitsInfo = function () {
  // return request.post(
  //   "https://mock.xiaojukeji.com/mock/12448/api/benefitgrowth/getBenefitsInfo"
  // );
  return request.post(BENEFITS_INFO);
};

// 电话
export const serviceNumber = {
  MX: '8000673469',
  BR: '03003130099',
  JP: '0120132355',
  CR: '(+506)40015122',
  CO: '(+57)6015144433',
  DO: '(+) 18 29956 8643',
  CL: '(+56)227120527',
  PE: '(+51)16423434',
}

// 邮箱
export const serviceEmail = {
  MX: 'soporte.food@mx.didiglobal.com',
  BR: '', // 巴西暂时没有邮箱配置
  JP: 'customer@didifood.co.jp',
  CR: '',
  CO: 'didifood.co@didiglocal.com',
  DO: '', // 多米尼加暂不配置邮箱
  CL: '', // 智利暂不配置邮箱
  PE: '', // 秘鲁暂不配置邮箱
}

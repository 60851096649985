function updateCancelSuccessText (state, payload) {
  state.cancelSuccessText = payload
}
function updateCancelSuccessTip (state, payload) {
  state.cancelSuccessTip = payload
}
export default {
  updateCancelSuccessText,
  updateCancelSuccessTip
}

import didiaxios from '@didi/dajax/dist/dajax'

const BASE_URL = '//c.didi-food.com'

const MOCK_LOGIN = BASE_URL + '/mock/login'
const MOCK_GET_SHOP_LIST = BASE_URL + '/mock/shoplist'
const MOCK_ORDER_CREATE = BASE_URL + '/mock/create'
const MOCK_ORDER_SHOPPAYED = BASE_URL + '/mock/shoppayed'
const MOCK_ORDER_RIDERUPDATE = BASE_URL + '/mock/riderupdate'

export const mockLogin = function (data) {
  return requestPOST(MOCK_LOGIN, data)
}
export const mockGetShopList = function (data) {
  return requestGET(MOCK_GET_SHOP_LIST, data)
}
export const mockOrderCreate = function (data) {
  return requestPOST(MOCK_ORDER_CREATE, data)
}
export const mockOrderShopPayed = function (data) {
  return requestPOST(MOCK_ORDER_SHOPPAYED, data)
}
export const mockOrderRideUpdate = function (data) {
  return requestPOST(MOCK_ORDER_RIDERUPDATE, data)
}

function requestPOST (url, data) {
  return didiaxios.request({
    url: url,
    method: 'POST',
    emulateJSON: true,
    needEncrypt: true,
    params: '',
    body: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

function requestGET (url, data) {
  return didiaxios.request({
    url: url,
    method: 'GET',
    emulateJSON: true,
    needEncrypt: true,
    params: data
  }).then(res => (res.data)).catch((err) => console.log(err))
}

import * as bd from './bd/index'
import * as SidebarCoupon from './sidebar/coupon'
import * as SidebarRcoupon from './sidebar/rcoupon'
import * as SidebarMembership from './sidebar/membership'

import * as CartCoupon from './cart/coupon'
import * as CartRCoupon from './cart/rcoupon'
import * as CancelOrder from './cancel-order'
import * as Evaluation from './evaluation'
import * as Abnormal from './abnormal'
import * as Question from './question'
import * as Other from './other'
import * as Feedback from './feedback'
import * as MissOrder from './miss-order'
import * as Entrega from './entrega'
import * as Merchants from './merchants/couponInfo'
import * as VFM from './vfm'
import * as Deeplink from './deeplink'
import * as User from './user/coupon'
import * as SoloDining from './soloDining'
import * as Topic from './topic'
import * as Bill from './bill'

const API = {
  ...bd,
  ...SidebarRcoupon,
  ...SidebarCoupon,
  ...SidebarMembership,
  ...CartCoupon,
  ...CartRCoupon,
  ...CancelOrder,
  ...Evaluation,
  ...Abnormal,
  ...Question,
  ...Other,
  ...Feedback,
  ...MissOrder,
  ...Entrega,
  ...Merchants,
  ...VFM,
  ...User,
  ...SoloDining,
  ...Topic,
  ...Bill,
  ...Deeplink
}

export default API

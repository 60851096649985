const Delivery = {
  path: '/delivery',
  name: 'delivery',
  meta: {
    title: 'delivery'
  },
  component (resolve) {
    require.ensure([], () => resolve(require('../page/delivery/index')))
  }
}
export default [Delivery]

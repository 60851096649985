import { mapState } from 'vuex'
import { util } from '@didi/soda-common'
const { env } = util

export default {
  computed: mapState(['globalMsg', 'globalParams']),
  data () {
    return {
      _message: null
    }
  },
  watch: {
    globalMsg (config) {
      if (this.globalParams.sailingBizLine == 2) { // eslint-disable-line
        // 非外卖业务线，使用rich-toast
        if (config.show) {
          this.toast = this.$createToast({
            ...config,
            type: config.type === 'success' ? 'correct' : config.type
          })
          this.toast.show()
        } else {
          if (this.toast) {
            this.toast.hide()
            this.toast = null
          }
        }
      } else if (env.inSoda) {
        // 外卖业务线，使用bridge.toast
        SodaBridge('customer.toast', {
          type: config.type,
          txt: config.txt
        })
      } else {
        // 非外卖业务线，使用rich-toast
        if (config.show) {
          this.toast = this.$createToast({
            ...config,
            type: config.type === 'success' ? 'correct' : config.type
          })
          this.toast.show()
        } else {
          if (this.toast) {
            this.toast.hide()
            this.toast = null
          }
        }
      }
    }
  }
}
